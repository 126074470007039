
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { useAuth } from '@/composables/useAuth'
import { ERoles } from '@/enums/roles'
export default defineComponent({
    setup() {
        const router = useRouter()
        const { role } = useAuth()
        setTimeout(() => {
            if (role.value === ERoles.Manager) {
                router.push({ name: 'CarWashList' })
            }
            if (role.value === ERoles.Courier) {
                router.push({ name: 'DeliveryListCourier' })
            }
        }, 2000)
    },
})
